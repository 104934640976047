@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.light-theme {
  background-color: #000;

}

@font-face {
  font-family: 'Roboto-bold', sans-serif;
  src: local('Roboto-bold', sans-serif), url(./fonts/Roboto-Bold.ttf) format('ttf');
}

@font-face {
  font-family: 'Roboto-Black', sans-serif;
  src: local('Roboto-Black', sans-serif), url(./fonts/Roboto-Black.ttf) format('ttf');
}



h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto-bold", serif;
  font-style: normal;
  margin: 0 !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto-bold", serif;

}

.css-dev-only-do-not-override-htwhyh.ant-btn-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #1677ff !important;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}


:where(.css-htwhyh).ant-btn-primary {
  color: #fff;
  background-color: #1677ff !important;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}

a {
  text-decoration: none !important;
  color: #000;
}

[type=button],
[type=reset],
[type=submit],
button {
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
}

.bold {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 33px;
  color: #080808;

}

@media screen and (min-width: 901px) {
  body {
    /* background-image: url("../src/1024.jpg"); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
}


.site-description-item-profile-wrapper {
  margin-bottom: 7px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
}

.ant-drawer-body p.site-description-item-profile-p {
  display: block;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 1.5715;
}

.site-description-item-profile-p-label {
  display: inline-block;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.85);
}
.containergdf {
    max-width: 400px;
    margin: 15px  auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .spangfgf {
    font-weight: bold;
    font-size: 20px; /* Maaşı büyük hale getirir */
    display: block; /* Satır sonuna geçer */
    /* margin-top: 10px; Boşluk ekler */
    color: #007bff;
  }
@font-face {
  font-family: 'Roboto-black', sans-serif;
  src: local('Roboto-black', sans-serif),
    url(./fonts/Roboto-Bold.ttf) format('ttf');
}

@font-face {
  font-family: 'Roboto-bold', sans-serif;
  src: local('Roboto-bold', sans-serif),
    url(./fonts/Roboto-Black.ttf) format('ttf');
}

.App {
  text-align: center;
}

.ant-btn-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}

:where(.css-htwhyh).ant-btn-primary {
  color: #fff;
  background-color: #1677ff !important;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}

:where(.css-dev-only-do-not-override-htwhyh).ant-modal .ant-modal-footer {
  display: flex;
  text-align: end;
  background: transparent;
  margin-top: 12px;
  justify-content: flex-end;
}

.shfdjx {
  border-radius: 18px;
  border: 1px solid var(--gray-200, #eaecf0);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.gds {
  height: 100vh;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.custom-table .ant-table-container {
  overflow: auto !important;
}

.custom-table .ant-table-thead > tr > th,
.custom-table .ant-table-tbody > tr > td {
  white-space: nowrap;
}

.ColorMain {
  display: flex;
  align-items: center;
  background-color: #784eff;
  color: #fff;
  justify-content: center;
}

.ant-modal-mask {
  z-index: 1000;
  /* or a higher value */
}

.ant-modal-wrap {
  z-index: 1001;
}

:where(.css-dev-only-do-not-override-htwhyh).ant-space {
  display: flex !important;
  justify-content: flex-end;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
}

#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.container {
  max-width: 1100px;
  margin: auto;
  /* text-align: center; */
}

.fshdvccharg0e {
  position: absolute;
  margin-bottom: -975px;
  margin-left: -35px;
  color: #fff;
  font-size: 40px;
  font-weight: bold;
}

.fshdvccharg0dsgf {
  position: absolute;
  margin-top: 932px;
  /* margin-left: -35px; */
  color: #fff;
  font-size: 40px;
  font-weight: bold;
}

.fshdvccharg0dsg4 {
  position: absolute;
  margin-top: 761px;
  /* margin-left: -35px; */
  color: #fff;
  font-size: 40px;
  font-weight: bold;
}

.spacevertical {
  display: flex;
  max-width: 75px;
}

.infasd {
  max-width: 30px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* 外壳样式 */
.table-enter-leave-demo-wrapper {
  position: relative;
  background: #abc3ff;
  overflow: hidden;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-enter-leave-demo {
  box-shadow: 0 10px 40px #506cb2;
  width: 60%;
  min-width: 550px;
  height: 440px;
  background: #f4f4f4;
  border-radius: 6px;
  overflow: hidden;
}

.table-enter-leave-demo-header {
  height: 32px;
  line-height: 32px;
  background: #d8d8d8;
}

.table-enter-leave-demo-header ul li {
  list-style: none;
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 4px;
  margin: 0 5px 0 0;
}

.table-enter-leave-demo-header ul li:nth-child(1) {
  background: #cf3333;
  margin-left: 3%;
}

.table-enter-leave-demo-header ul li:nth-child(2) {
  background: #dec811;
}

.table-enter-leave-demo-header ul li:nth-child(3) {
  background: #28c026;
}

.table-enter-leave-demo-header ul li:nth-child(4) {
  background: #fff;
  width: 80%;
  height: 16px;
  vertical-align: middle;
  margin: 0 10px;
}

.table-enter-leave-demo-header ul li:nth-child(5),
.table-enter-leave-demo-header ul li:nth-child(5):before,
.table-enter-leave-demo-header ul li:nth-child(5):after {
  width: 20px;
  height: 2px;
  background: #bababa;
  border-radius: 2px;
}

.table-enter-leave-demo-header ul li:nth-child(5) {
  vertical-align: middle;
  margin-left: 5px;
  position: relative;
}

.table-enter-leave-demo-header ul li:nth-child(5):before,
.table-enter-leave-demo-header ul li:nth-child(5):after {
  content: '';
  display: block;
  position: absolute;
}

.table-enter-leave-demo-header ul li:nth-child(5):before {
  top: -6px;
}

.table-enter-leave-demo-header ul li:nth-child(5):after {
  top: 6px;
}

/* 外壳 end */

.table-enter-leave-demo-nav {
  height: 50px;
  background: #080b20;
  line-height: 50px;
}

.table-enter-leave-demo-nav span {
  margin-left: 20px;
}

.table-enter-leave-demo-nav img:first-child {
  filter: grayscale(1) brightness(3);
}

.table-enter-leave-demo-nav img:last-child {
  margin-left: 10px;
}

.table-enter-leave-demo-list {
  width: 20%;
  height: calc(100% - 82px);
  display: inline-block;
  background: #171c3e;
}

.table-enter-leave-demo-list ul li {
  width: 80%;
  height: 15px;
  background: #23274a;
  margin: 20px auto;
  border-radius: 4px;
}

.table-enter-leave-demo-table-wrapper {
  width: 80%;
  display: inline-block;
  overflow: auto;
  height: calc(100% - 82px);
}

.table-enter-leave-demo-action-bar {
  margin: 20px;
  background: #fff;
  line-height: 40px;
  height: 40px;
  position: relative;
  border-radius: 4px;
}

.table-enter-leave-demo-action-bar:before,
.table-enter-leave-demo-action-bar:after {
  content: '';
  display: inline-block;
  background: #e5e5e5;
  vertical-align: middle;
}

.table-enter-leave-demo-action-bar:before {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin-left: 10px;
}

.table-enter-leave-demo-action-bar:after {
  position: absolute;
  width: 100px;
  height: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 40px;
}

.table-enter-leave-demo-action-bar button {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 80px;
  height: 20px;
  border-radius: 4px;
  line-height: 1;
}

.table-enter-leave-demo-delete {
  color: #019bf0;
  cursor: pointer;
}

.table-enter-leave-demo-table {
  margin: 0 20px;
  overflow: hidden;
}

.table-enter-leave-demo-table .ant-table-thead > tr > th {
  background: #e5e5e5;
  padding: 10px 8px;
  display: inline-block;
}

.table-enter-leave-demo-table .ant-table-tbody > tr {
  background: #fff;
  /* 不能用
  border-bottom: 1px solid #e9e9e9;
  */
}

.table-enter-leave-demo-table .ant-table-tbody > tr > td {
  padding: 10px 8px;
  display: inline-block;
  vertical-align: bottom;
}

.table-enter-leave-demo-table .ant-table-thead > tr,
.table-enter-leave-demo-table .ant-table-tbody > tr {
  display: block;
}

.table-enter-leave-demo-table .ant-table-thead > tr,
.table-enter-leave-demo-table .ant-table-tbody > tr {
  transition: none;
}

.table-enter-leave-demo-table .ant-table-thead > tr > th:nth-child(1),
.table-enter-leave-demo-table .ant-table-tbody > tr > td:nth-child(1) {
  width: 25%;
}

.table-enter-leave-demo-table .ant-table-thead > tr > th:nth-child(2),
.table-enter-leave-demo-table .ant-table-tbody > tr > td:nth-child(2) {
  width: 15%;
}

.table-enter-leave-demo-table .ant-table-thead > tr > th:nth-child(3),
.table-enter-leave-demo-table .ant-table-tbody > tr > td:nth-child(3) {
  width: 40%;
}

.table-enter-leave-demo-table .ant-table-thead > tr > th:nth-child(4),
.table-enter-leave-demo-table .ant-table-tbody > tr > td:nth-child(4) {
  width: 20%;
}

@media screen and (max-width: 414px) {
  .table-enter-leave-demo {
    transform: scale(0.65) translateX(12px);
    transform-origin: left center;
  }
}

@media screen and (max-width: 375px) {
  .table-enter-leave-demo {
    transform: scale(0.6) translateX(7px);
  }
}

@media screen and (max-width: 320px) {
  .table-enter-leave-demo {
    transform: scale(0.5) translateX(12px);
  }
}

body h1 {
  font-weight: 300;

  color: #000;
}

body h3 {
  font-weight: 600;
  color: #555;
}

body a {
  color: #714bec;
}

.disocs {
  display: flex;
}

.invoice-box {
  max-width: 800px;
  margin: auto;
  padding: 30px;
  /* border: 1px solid #eee; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.15); */
  font-size: 16px;
  line-height: 24px;
  font-family: 'Roboto-black', sans-serif, sans-serif;
  color: #555;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
  border-collapse: collapse;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
  text-align: right;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

#components-form-demo-normal-login .login-form {
  max-width: 300px;
}

#components-form-demo-normal-login .login-form-forgot {
  float: right;
}

#components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
  float: left;
}

:where(.css-dev-only-do-not-override-htwhyh).ant-menu-inline.ant-menu-root
  .ant-menu-item
  > .ant-menu-title-content,
:where(.css-dev-only-do-not-override-htwhyh).ant-menu-inline.ant-menu-root
  .ant-menu-submenu-title
  > .ant-menu-title-content {
  flex: none;
}

.contetn {
  position: relative;
  display: flex;
  margin: auto;
  height: 93vh;
}

.bg-login {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #2352d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  margin: 0;
  padding: 0;
  height: 100vh;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.disabled {
  background-color: #6d6d6d10;
  /* Change the background color to your preferred disabled color */
  /* pointer-events: none; */
  color: #7e7e7e;
}

.blockicondfaa {
  color: rgba(255, 0, 0, 0.349);
}

.grayscale {
  filter: grayscale(100%);
}

.ccolorblock {
  background-color: #9d82f3;
  color: rgba(255, 255, 255, 0.808);
}

.webcam-container {
  display: flex;
  justify-content: center;
  max-width: 300px;
  align-items: center;
  flex-direction: column;
  gap: 12px;
}

.webdfS {
  min-height: 380px;
  background: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 22px;
}

.actions-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 371px;
}

.dfkvasd {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 371px;
}

@media only screen and (max-width: 600px) {
  .actions-container {
    max-width: 171px;
  }
}

.login-form {
  margin: auto;
  padding: 40px;
  border-radius: 10px;
  background: #ffffff;
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 300px;
}

.form-Add {
  margin: auto;
  padding: 40px;
  border-radius: 10px;
  background: #ffffff;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.imgsdsdss {
  width: 50px !important;
}

.login-formloga {
  position: relative;
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: center;
  gap: 18px;
}

.login-form-footer {
  background-color: transparent;
  margin-bottom: 0;
  color: #8a8a8a;
  display: flex;
  justify-content: flex-start;
  /* Align content to the left side */
  gap: 12px;
}

.logdsfdsh {
  background-color: transparent;
  margin-bottom: 0;
  color: #8a8a8a;
  display: flex;
  justify-content: flex-end;
  /* Align content to the left side */
}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

.events {
  margin: 0;
  padding: 0;
  list-style: none;
}

.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.notes-month {
  font-size: 28px;
  text-align: center;
}

.notes-month section {
  font-size: 28px;
}

.container-Drawer {
  margin: auto;
  padding: 20px;
}

.select {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 0 10px;
  font-size: 16px;
  color: #333;
  outline: none;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

#components-form-demo-control-hooks .ant-btn {
  margin-right: 8px;
}

.ID-USER {
  display: flex;
  justify-content: flex-end;
}

.site-form-in-drawer-wrapper {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 100%;
  min-width: 700px;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
  border-top: 1px solid #e9e9e9;
}

.card-chart {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 123px;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.margin-chart {
  margin: 5px;
  max-width: 628px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 68, 255, 0.527);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(0, 68, 255);
}

.asfsdfadsrfg {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-items: stretch;
}

.cardStat {
  min-width: 165px;
  max-width: 100%;
}

@media screen and (max-width: 600px) {
  .asfsdfadsrfg {
    flex-wrap: wrap;
  }

  .camera-drawer {
    display: none;
  }
}

/* Add these styles to make the webcam stream responsive */
.webcam-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.adplgfsd {
  display: flex;
  position: relative;
  top: -878px;
}

.webcam-img {
  position: relative;
  width: 100%;
  /* border: 2px solid #ff0000; */
  /* Make the webcam stream container full width */
}

:where(.css-dev-only-do-not-override-htwhyh).ant-card .ant-card-body {
  padding: 15px !important;
}

/* Add media queries for different screen sizes and orientations */
@media (orientation: portrait) {
  .webcam-stream {
    width: 100%;
    /* Set the width to 100% in portrait orientation */
    height: auto;
    /* Auto-adjust the height */
  }
}

@media (orientation: landscape) {
  .webcam-stream {
    width: auto;
    /* Auto-adjust the width */
    height: 400px;
    /* Set a fixed height in landscape orientation */
  }
}

.imgPP {
  width: 76px;
  /* height: 164px;*/
  border: 2px solid rgb(255, 0, 55);
  border-radius: 9px;
}

.imgSH {
  width: 100%;
  height: 80vh;
}

.dashboard {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}

.CarVisit {
  width: 205px;
  height: 326px;
  margin-left: 90px;
  margin-top: -70px;
  transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
}
.CarVisitGRL {
  width: 190px;
  height: 315px;
  margin-left: 90px;
  margin-top: -70px;
  transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
}

.CarVisitALL {
  width: 205px;
  height: 326px;
  margin-left: 43px;
  margin-top: -120px;
  transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
}

.products {
  padding: 0px 0 0 55px;
  display: flex;
  gap: 5px;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 665px;
  margin-top: -1664px;
}

.useridid {
  color: red !important;
  font-weight: bold;
  font-family: 'Roboto-bold', sans-serif;
  font-size: 20px;
}

.ihd {
  padding: 13px;
  height: 100%;
  width: 100%;
  background-image: url(./images/backcart.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.UZVihdBCK {
  padding: 13px;
  height: 100%;
  width: 100%;
  background-image: url(./images/membercardback.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.UZVihd {
  padding: 13px;
  height: 100%;
  width: 100%;
  background-image: url(./images/membercard.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.ihdц {
  padding: 12px;
  height: 100%;
  background-image: url(./images/personal_vesiqeBoş.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.ihdцGE {
  padding: 12px;
  height: 100%;
  background-image: url(./images/personal_vesiqeBoşge.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.isd {
  font-family: 'Roboto-bold', sans-serif;
  font-size: 16px;
}

.qrcartd {
  border: 2px solid red;
  border-radius: 10px;
  padding: 7px;
  background-color: #fff;
}

.Namecart {
  font-weight: bold;
  width: 86px;
  height: 23px;
}

.Namecartd {
  font-weight: bold;
  width: 86px;
  height: 17px;
}

.fontsad {
  font-weight: bold;
  text-align: right;
  font-family: 'Roboto-bold', sans-serif;
  font-size: 16px;
}

.fontsadUZV {
  font-weight: bold;
  text-align: right;
  font-family: 'Roboto-bold', sans-serif;
  font-size: 24px;
}

.invoicx {
  margin-top: 10px;
}

.qr-name {
  display: flex;
  margin-top: 10px;
  height: 80px;
}

.qr-nameAll {
  display: flex;
  margin-top: -5px;
  height: 80px;
}

.hsaddfa {
  gap: 3px;
  padding: 5px;
  width: 100px;
  position: absolute;
  right: 16px;
  top: 155px;
}
.blur {
  filter: blur(5px);
}

.hsaddfaUZV {
  gap: 3px;
  padding: 5px;
  /* width: 100px; */
  position: absolute;
  right: -12px;
  top: 108px;
}

.fondfg {
  font-size: 15px;
  color: red;
  font-weight: 900;
  font-family: 'Roboto-black', sans-serif;
  width: 120px;
}

.ojdkde {
  display: flex;
}

.jhasdfda {
  font-size: 18px;
  position: absolute;
  color: #3e5ba5;
  font-weight: bolder;
  font-family: 'Roboto-bold', sans-serif;
  top: 106px !important;
  left: 12px !important;
  transform: rotate(-90deg);
}

.jhasdfd {
  color: #3e5ba5;
  font-size: 13px;
  font-weight: bold;
}

.jhasd {
  font-size: 67px;
  position: absolute;
  color: #3e5ba5;
  font-weight: bolder;
  font-family: 'Roboto-bold', sans-serif;
  top: -2px;
  left: -7px;
  transform: rotate(-90deg);
}

.qrcartdWR {
  border: 2px solid #3e5ba5;
  padding: 3px;
}

.qr-nameWR {
  display: flex;
  margin-top: 10px;
  height: 54px;
  position: relative;
  /* left: 124px; */
  top: -10px;
  justify-content: flex-end;
}

.hsaddfaWE {
  padding: 5px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-end;
  margin-top: -11px;
}

.useridW {
  color: #2b2a29 !important;
  font-weight: bold;
  font-family: 'Roboto-bold', sans-serif;
  font-size: 20px;
  display: flex;
  justify-content: flex-end;
  margin-top: 31px;
}

.ojdkdeW {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.imgPPW {
  width: 98px;
  /* height: 164px; */
  border: 2px solid #4b67ac;
  border-radius: 9px;
  margin-top: 3px;
}

.jiudgf {
  font-size: 83px;
  color: #ff0000e8;
  font-weight: 900;
  font-family: 'Roboto-bold', sans-serif;
}

.jjhgdf {
  background-image: url(./images/pdfBack.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100vh;
  padding: 13px;
  display: flex;
  height: 1333vh;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.geupdf {
  background-image: url(./images/pdfBackgeu.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100vh;
  padding: 13px;
  display: flex;
  height: 1333vh;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.pagegeu {
  display: flex;
  position: relative;
  width: 211mm;
  height: 1128px;
  font-size: 12pt;
  /* margin: 2em auto; */
  padding: calc(var(--bleeding) + var(--margin));
}

.GrilsBackpsf {
  background-image: url(./images/pdfGirls.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100vh;
  padding: 13px;
  display: flex;
  height: 1333px;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.jjhgdfstaff {
  background-image: url(./images/pdfpersonal.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100vh;
  padding: 13px;
  display: flex;
  height: 1333px;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.imgPPDF {
  width: 120px;
}

.fdjdx {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  padding: 10px;
  max-width: 306px;
  max-height: 400px;
}

.imgLKSH {
  height: 100%;
  max-width: 479px;
}

.hw3e {
  font-size: 26px;
  font-family: 'Roboto-black', sans-serif;
  color: #000;
}

.ssda {
  color: red;
}

.ttextsd {
  display: flex;
  gap: 8px;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
}

.namessd {
  font-size: xx-large;
}

.hjhfm {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 19px 60px;
  width: 531px;
  margin-left: 154px;
  /* margin-top: 112px; */
  align-items: flex-start;
  justify-content: space-between;
}

.spandirver {
  font-size: 31px;
  position: absolute;
  top: 62px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
  text-align: center;
}

.pageStaff {
  display: flex;
  position: relative;
  width: 211mm;
  height: 1128px;
  font-size: 12pt;
  /* margin: 2em auto; */
  padding: calc(var(--bleeding) + var(--margin));
}

.pageStaffge {
  display: flex;
  position: relative;
  width: 211mm;
  height: 1128px;
  font-size: 12pt;
  margin: 0;
  bleed: 0;
  padding: 0;
  /* padding: calc(var(--bleeding) + var(--margin)); */
}

.jjhgdfstaffgeu {
  background-image: url(./images/pdfpersonalgeu.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* width: 211mm; */
  /* padding: 13px; */
  display: flex;
  /* height: 1128px; */
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.page {
  display: flex;
  position: relative;
  width: 211mm;
  height: 1128px;
  font-size: 12pt;
  /* margin: 2em auto; */
  padding: calc(var(--bleeding) + var(--margin));
}

.pages {
  display: inline-block;
  position: relative;
  height: 279mm;
  width: 201mm;
  font-size: 12pt;
  margin: 2em auto;
  padding: calc(var(--bleeding) + var(--margin));
  opacity: 0.9;
}

.pagejsd {
  height: 1150px !important;
}

.pages-geo-all {
  display: inline-block;
  position: relative;
  /* height: 9mm; */
  width: 201mm;
  font-size: 12pt;
  /* margin: 2em auto; */
  /* padding: calc(var(--bleeding) + var(--margin)); */
  /* opacity: 0.9; */
}

.Block {
  display: inline-block;
  position: relative;
  height: 279mm;
  width: 201mm;
  font-size: 12pt;
  /* margin: 2em auto; */
  padding: calc(var(--bleeding) + var(--margin));
  z-index: +999;
  position: absolute;
  background: #00000073;
}

@media print {
  .page {
    background-image: url(./images/pdfBack.jpg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

@media print {
  .pageStaff {
    background-image: url(./images/pdfpersonal.jpg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

@media print {
  .pages {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

.hgasd {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.dfksmcj {
  display: flex;
  gap: 16px;
}

@media (max-width: 900px) {
  .dfksmcj {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
}

:where(.css-dev-only-do-not-override-htwhyh).ant-row {
  display: flex;
  flex-flow: row wrap;
  min-width: 0;
  gap: 10px;
  justify-content: space-evenly;
}

.hw3hf {
  max-width: 200px;
  font-size: 64px;
  font-weight: bold;
  color: #0e79fe;
  font-family: 'Roboto-bold', sans-serif;
}

.stsff {
  font-size: 16px;
  font-family: 'Roboto-bold', sans-serif;
  color: #000;
}

.ssaStaf {
  font-size: 20px;
}

.hwfd {
  font-size: 33px;
  color: #0e79fe;
}

.fohsd {
  font-size: large;
  color: #0e79fe;
}

.formfsd {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}

.ant-form-item {
  max-width: 290px !important;
  width: 100% !important;
}

.light-effect {
  filter: brightness(1.2);
}

.ant-col {
  max-width: 100% !important;
}

/* Menu Pages */

@media print {
  .page-menu-1 {
    background-image: url(./images/menu-back-1.png);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100%;
    width: 100%;
  }
}

.jjhgdf-menu-1 {
  background-image: url(./images/menu-back-1.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 825px;
  padding: 13px;
  display: flex;
  height: 1053px;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

@media print {
  .page-menu-2 {
    background-image: url(./images/menu-back-2.png);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100%;
    width: 100%;
  }
}

.jjhgdf-menu-2 {
  background-image: url(./images/menu-back-2.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 825px;
  padding: 13px;
  display: flex;
  height: 1056px;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

@media print {
  .page-menu-3 {
    background-image: url(./images/menu-back-3.png);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 100%;
    height: 100%;
    width: 100%;
  }
}

.jjhgdf-menu-3 {
  background-image: url(./images/menu-back-3.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 825px;
  padding: 13px;
  display: flex;
  height: 1056px;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

@media print {
  .page-menu-4 {
    background-image: url(./images/menu-back-4.png);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

.jjhgdf-menu-4 {
  background-image: url(./images/menu-back-4.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 825px;
  padding: 13px;
  display: flex;
  height: 1058px;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

@media print {
  .page-menu-5 {
    background-image: url(./images/menu-back-5.png);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

.jjhgdf-menu-5 {
  background-image: url(./images/menu-back-5.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 825px;
  padding: 13px;
  display: flex;
  height: 1060px;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

@media print {
  .page-menu-6 {
    background-image: url(./images/menu-back-6.png);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

.jjhgdf-menu-6 {
  background-image: url(./images/menu-back-6.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 825px;
  padding: 13px;
  display: flex;
  height: 1050px;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

@media print {
  .page-menu-7 {
    background-image: url(./images/menu-back-7.png);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

.jjhgdf-menu-7 {
  background-image: url(./images/menu-back-7.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 825px;
  padding: 13px;
  display: flex;
  height: 1052px;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

@media print {
  .page-menu-8 {
    background-image: url(./images/menu-back-8.png);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

.jjhgdf-menu-8 {
  background-image: url(./images/menu-back-8.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 825px;
  padding: 13px;
  display: flex;
  height: 1064px;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

@media print {
  .page-menu-9 {
    background-image: url(./images/menu-back-9.png);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

.jjhgdf-menu-9 {
  background-image: url(./images/menu-back-9.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 825px;
  padding: 13px;
  display: flex;
  height: 1054px;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

@media print {
  .page-menu-10 {
    background-image: url(./images/menu-back-10.png);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

.jjhgdf-menu-10 {
  background-image: url(./images/menu-back-10.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 825px;
  padding: 13px;
  gap: 40px;
  padding-top: 226px;
  display: flex;
  height: 1055px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.page-menu-10-Geo {
  background-image: url(./images/menu-back-10.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.jjhgdf-menu-10-Geo {
  background-image: url(./images/menu-back-10.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 825px;
  padding: 13px;
  gap: 40px;
  padding-top: 226px;
  display: flex;
  height: 1055px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.page-menu-11-Geo {
  background-image: url(./images/menu-back-11.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.jjhgdf-menu-11-Geo {
  background-image: url(./images/menu-back-11.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 825px;
  padding: 13px;
  gap: 40px;
  padding-top: 226px;
  display: flex;
  height: 1055px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.page-menu-12-Geo {
  background-image: url(./images/menu-back-12.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.jjhgdf-menu-12-Geo {
  background-image: url(./images/menu-back-12.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 825px;
  padding: 13px;
  gap: 40px;
  padding-top: 226px;
  display: flex;
  height: 1055px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

:where(.css-htwhyh).ant-row {
  display: flex;
  flex-flow: row wrap;
  min-width: 0;
  justify-content: space-evenly;
}

.imgpp {
  max-width: 45px;
  border-radius: 5px;
}

.cardssds {
  display: flex;
  font-size: 16px;
  font-family: 'Roboto-black', sans-serif;
  color: #ffffff;
  justify-content: space-between;
  align-items: center;
  width: 325px;
  height: 17px !important;
}

.ssfsdb {
  display: flex;
  height: 754px;
  gap: 6px;
  column-gap: 62px;
  max-height: 754px;
  align-content: center;
  justify-content: space-evenly;
  flex-direction: column;
  flex-wrap: wrap;
}

.ssfsdbVOD {
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 58px;
  flex-wrap: wrap;
  padding: 15px;
  max-height: 643px;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
}

.ssfsdbVODfdh {
  display: flex;
  gap: 10px;
  column-gap: 62px;
  flex-direction: column;
  justify-content: center;
  margin-left: -322px;
}

.cardssdsVODKA {
  display: flex;
  font-size: 18px;
  font-family: 'Roboto-black', sans-serif, sans-serif;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  width: 380px;
  height: 13px !important;
}

.ssfsdbWC {
  display: flex;
  height: 919px;
  gap: 10px;
  width: 366px;
  column-gap: 62px;
  /* max-height: 978px; */
  flex-direction: column;
  justify-content: center;
  margin-left: 0px;
  margin-top: -18px;
}

.ssfsdbWCH {
  display: flex;
  gap: 15px;
  column-gap: 62px;
  max-height: 978px;
  flex-direction: column;
  justify-content: center;
  /* margin-left: -520px; */
  margin-top: 27px;
}

.dhvsj {
  display: flex;
  margin-left: -298px;
  height: 773px;
  margin-top: 53px;
  flex-direction: column;
  justify-content: space-between;
}

.cardssdsWİNE {
  display: flex;
  font-size: 18px;
  font-family: 'Roboto-black', sans-serif, sans-serif;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  width: 380px;
  height: 13px !important;
  /* margin-left: 1068px; */
  flex-wrap: nowrap;
  flex-direction: row;
}

.cardssdsCHAMPAGNE {
  display: flex;
  font-size: 15px;
  font-family: 'Roboto-black', sans-serif, sans-serif;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  height: 13px !important;
}

.dhvsjd {
  display: flex;
  gap: 25px;
  height: 909px;
  margin-top: -78px;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 0px;
  align-items: flex-start;
}

.dhvsjdfd {
  display: flex;
  gap: 78px;
  height: 909px;
  margin-top: -78px;
  flex-direction: row;
  justify-content: space-between;
}

.dhvsjdfg {
  display: flex;
  gap: 30px;
  height: 746px;
  margin-left: -300px;
  margin-top: -45px;
  flex-direction: column;
  justify-content: space-between;
}

.ssfsdbTEQUILA {
  display: flex;
  /* height: 989px; */
  gap: 15px;
  /* position: relative; */
  /* top: -111px; */
  width: 322px;
  column-gap: 62px;
  max-height: 978px;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  margin-top: 129px !important;
}

.ssfsdbGIN {
  display: flex;
  /* height: 989px; */
  gap: 15px;
  column-gap: 62px;
  width: 316px;
  /* max-height: 978px; */
  flex-direction: column;
  justify-content: center;
  /* margin-left: -1055px; */
  margin-top: 130px;
}

.APERETIVasf {
  display: flex;
  height: 414px;
  gap: 10px;
  column-gap: 62px;
  max-height: 961px;
  flex-direction: column;
  justify-content: center;
  /* margin-left: -380px; */
}

.APERETIVasft {
  display: flex;
  height: 222px;
  gap: 10px;
  column-gap: 62px;
  max-height: 961px;
  flex-direction: column;
  justify-content: center;
  /* margin-left: -380px; */
}

.ssfsagasdf {
  display: flex;
  height: 173px;
  gap: 10px;
  column-gap: 62px;
  max-height: 950px;
  flex-direction: column;
  justify-content: center;
  /* margin-left: -380px; */
  /* margin-top: 161px; */
}

.SADFGV {
  display: flex;
  /* height: 989px; */
  gap: 10px;
  column-gap: 62px;
  max-height: 916px;
  flex-direction: column;
  justify-content: center;
  /* margin-left: -380px; */
  /* margin-top: 176px;*/
}

.ssfsdbVODs {
  display: flex;
  height: 128px;
  gap: 10px;
  column-gap: 62px;
  max-height: 846px;
  flex-direction: column;
  justify-content: center;
  margin-left: -322px;
}

.dhjsfbs2 {
  font-size: 53px;
  color: #ffde00;
  margin-bottom: 15px !important;
  /* position: absolute; */
}

.djhvms {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 30px;
  width: 742px;
  margin-top: 47px;
  align-items: flex-start;
  align-items: stretch;
  padding: 15px;
  backdrop-filter: blur(25px);
}

.sfsdfaf {
  display: flex;
  margin-top: 102px;
  margin-left: -300px;
  /* gap: 27px; */
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
}

.jkhgdfv {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}

.logodfsaf {
  max-width: 90px;
}

.imsdasd {
  background-image: url(./images/imfdgs.png);
  height: 100px;
  background-repeat: no-repeat;
}

@media print {
  .bime-tarixi {
    display: none;
  }
}

.bg-map {
  position: absolute;
  /* z-index: -1; */
}

.map {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dibha {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: flex-start;
}

@media print {
  .print-table {
    display: table !important;
    display: none;
  }

  .dibhaa {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
  }

  .listps {
    width: 830px !important;
  }

  .listps {
    visibility: hidden !important;
    display: none;
  }

  .buttonprint {
    visibility: hidden !important;
    display: none;
  }

  .pritlistrezv {
    visibility: visible !important;
  }

  .greenbox {
    background-color: #41fc6a;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    border-radius: 2px;
  }

  .greenboxBorder {
    border: 3px solid #41fc6a;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    border-radius: 2px;
  }

  .redbox {
    background-color: #ff0000;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    border-radius: 2px;
  }
}

.infosds {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.ckdj {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.greenbox {
  background-color: #41fc6a;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  border-radius: 2px;
}

.greenboxBorder {
  border: 3px solid #41fc6a;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  border-radius: 2px;
}

.redbox {
  background-color: #ff0000;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  border-radius: 2px;
}

#content_demo_image {
  float: left;
  margin-bottom: 10px;
}

.image_wrapper {
  float: left;
  position: relative;
  display: inline-block;
}

.api_face {
  opacity: 0.8;
  background: transparent;
  position: absolute;
}

.api_face_inner {
  width: 100%;
  height: 100%;
  border: 5px solid #88cc00;
  position: relative;
}

.api_face_inner_tid {
  color: #88cc00;
  font-weight: bold;
  font-size: 20px;
  margin-top: -26px;
}

.api_face_point {
  position: absolute;
  width: 3px;
  height: 3px;
  background: #88cc00;
  border-radius: 50%;
  border: 1px solid #88cc00;
  margin: -2px 0 0 -2px;
}

.api_face_all_point {
  position: absolute;
  width: 3px;
  height: 3px;
  background: #e4d00a;
  border-radius: 50%;
  border: 1px solid #e4d00a;
  margin: -2px 0 0 -2px;
}
.printpagetop {
  display: flex;
  padding: 20px;
  background: #959595;
  color: #fff;
  justify-content: center;
}

@media print {
  .page-break {
    page-break-before: always;
  }
}
